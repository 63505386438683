.languages {
  position: absolute;
  z-index: 30;

  @media (max-width: $screen-sm-max) {
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (min-width: $screen-md-min) {
    position: fixed;
    top: 10px;
    right: 10px;
  }

  @media (min-width: $screen-lg-min) {
    right: 30px;
  }
}

.languages-list {
  @extend .list-inline;

  a {
    @media (min-width: $screen-sm-min) {
      color: $text-color;
    }
  }

  .current a {
    font-weight: 500;
  }
}
