@import "_header_base";

@mixin header-compact() {
  @include header-base;

  .header-logo {
    @include make-md-column(3);
  }

  .header-nav {
    @include make-md-column(9);
  }
}
