#google-maps {
	position: relative;
	min-height: 548px;
	max-width: 100%;
	margin: 0 auto;
	border-radius: 4px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	
	@media (max-width: $screen-md) {
		min-height: 300px;
	}
	
	.bg-image {
        position: absolute;
		background-image: url('../Images/layout/map-kontakt.svg');
        background-size: cover;
        background-repeat: no-repeat;
        height: 100%;
        width: 100%;
        filter: blur(3px);
        -webkit-filter: blur(3px);
        opacity: 0.6;
    }
	
	p {
		color: black;
	}

	.google-maps-wrapper {
		border-radius: 4px;
		background-color: white;
		height: fit-content;
		color: black;
		padding: 16px 25px;
		padding-top: 20px;
		margin: auto;
		width: 37%;
		z-index: 99;
		
		@media (max-width: $screen-sm) {
			width: 85%;
		}

		p {
			text-transform: none;
		}

		.google-maps-agreement-headline {
			position: relative;
			p {
				color: $color-red;
				font-weight: 600;
				line-height: 24px;

				text-align: center;
				font-size: 19px;
			}
		}

		.google-maps-agreement-text {
			position: relative;
			margin-bottom: 22px;
			
			p {
				color: black;
				font-weight: 300;
				text-align: center;
				font-size: 16px;
				line-height: 22.5px;
			}
		}
		.google-maps-opt-in-button {
			font-weight: $font-weight-normal;
			letter-spacing: 0.01rem;
			position: relative;
			display: flex;
			justify-content: center;
			column-gap: 17px;
			font-size: $font-size-small;
			border-radius: 4px;
			height: auto;
			
			.google-maps-opt-in-button-disagree , .google-maps-opt-in-button-agree  {
				text-align: center;
				display: flex;
				align-items: center;
				border-radius: 4px;
				cursor: pointer;
				width: 40%;
				padding: 5px 25px;
				line-height: 18px;
				
				@media (max-width: $screen-md) {
					width: 46%;
					padding: 5px 15px;
				}
			}

			.google-maps-opt-in-button-disagree {
				background-color: lightgray;
				color: black;
				background: #cecece;


				a {
					text-align: center;
					color: black;
				}
			}

			a {
				width: 100%;
				text-align: center;
			}

			.google-maps-opt-in-button-agree {
				background: $color-red;
				color: white;
				border: 1px solid $color-red;
				
				a {
					color: white;
				}
			}
			
			.google-maps-opt-in-button-agree:hover {
				border-radius: 4px;
				transition: 0.3s;
				background-color: #fff;
				color: $color-red;
				width: 40%;
				letter-spacing: 1.2;
				vertical-align: middle;
				
				a {
					color: inherit;
				}
			}
		}

		.google-text {
			position: relative;
			padding-top: 10px;
			opacity: 0.5;
		}
	}
}
