@include footer-compact;

.footer {
  height: 160px;
  padding: 20px 0;
  background-color: $brand-secondary;
}

.footer-copyright {
  @include make-sm-column(4);
  @include make-sm-column-offset(1);
  @include make-md-column(3);
  @include make-md-column-offset(1);
  font-size: $font-size-small;
  color: $color-white;
}

.footer-nav {
  @include make-xs-column(12);
  @include make-sm-column(6);
  @include make-sm-column-offset(0);
  @include make-md-column(6);
  @include make-md-column-offset(2);

  a {
    display: block;
    float: left;
    margin-right: 20px;
    color: $color-white;
    text-align: left;
    text-transform: uppercase;
  }
}

@media (min-width: $screen-sm-min) {
  .footer-nav {
    a {
      margin-right: 0;
      margin-left: 20px;
      text-align: right;
    }
  }
}
