.callout {
  @media (max-width: $screen-xs-max) {
    display: none;
  }

  .item {
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $brand-secondary;
  }

  .item {
    height: 573px;
  }

  .full.item  {
      height: calc(100vh);

      &::after {
        content: '';
        display: none;
        position: absolute;

        @media (min-height: 800px) {
          display: block;
        }
      }

      &::after {
        bottom: 15px;
        left: 50%;
        margin-left: -18px;
        background: transparent url("../Images/layout/hopp-down.svg") bottom center no-repeat;
        background-size: 56px 56px;
        width: 56px;
        height: 56px;
        z-index: 205;
      }
    }

  .carousel-control {
    background-image: none;
  }

  .carousel-control-icon {
    background-repeat: no-repeat;
    background-size: 45px;
    transition: opacity .1s;
    opacity: .5;

    &:hover {
      opacity: 1;
    }

    &.left {
      background-image: url("../Images/layout/callout-arrow-left.svg");
      background-position: 20% 48%;
    }

    &.right {
      background-image: url("../Images/layout/callout-arrow-right.svg");
      background-position: 70% 48%;
    }
  }
}

.callout-caption {
  @extend .container;
  height: 100%;
}

.callout-caption-box {
  display: block;
  height: auto;
  text-align: center;
  margin-top: 65%;
  z-index: 1000;

  p strong {
    @include callout-font;
    width: auto;
    padding: 0 15px;
    background-color: $callout-caption-bgcolor;
  }
}

@media (min-width: $screen-md-min) {
  .callout-caption-box {
    margin-top: 50%;
  }
}
