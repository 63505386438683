body {
  > .content {
    @extend .container;
    margin: 10px auto;
    min-height: calc(100vh);

    img {
      @extend .img-responsive;
    }

    ul {
      padding-left: 20px;
    }

    table {
      @extend .table;
    }
  }
}
