@mixin header-base() {
  .header-logo {
    @media (max-width: $screen-sm-max) {
      // Align logo and toggle
      position: absolute !important;
    }

    img {
      height: $header-logo-height-xs;

      @media (min-width: $screen-md) {
        height: $header-logo-height-md;
      }
    }
  }

  .header-nav {
    a {
      color: inherit;

      &:hover {
        color: darken($text-color, 15%);
      }
    }

    li {
      &.active > a,
      &.current > a {
        font-weight: 500;
      }
    }
  }

  .header-nav-list,
  .header-nav-sub {
    list-style-type: none;
  }

  .header-nav-list {
    padding: 0;

    // Add extra padding to prevent list from overlaying logo
    @media (max-width: $screen-sm-max) {
      padding-top: $header-logo-height-xs+20px;
    }

    li {
      @media (min-width: $screen-md) {
        display: inline-block;
      }
    }
  }

  .header-nav-sub {
    padding: 0 10px;
  }

  @media (min-width: $screen-md) {
    .header-nav-sub {
      position: absolute;
      left: 0;
      padding: 0 $grid-gutter-width/2;
      display: none;
    }

    .active .header-nav-sub {
      display: block;
    }
  }

  .header-nav-toggle {
    color: #000;

    button {
      border-color: #000;
    }

    span {
      background-color: #000;
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    .header-nav-content.collapse {
      display: block;
      visibility: visible;
      height: auto;
      overflow: visible;
    }
  }
}
