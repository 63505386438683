//
// Bootstrap
//
// http://getbootstrap.com/customize/#less-variables
// https://github.com/twbs/bootstrap-sass/blob/v3.3.1/assets/stylesheets/bootstrap/_variables.scss
//

// Grid
$grid-gutter-width: 30px;
$container-large-desktop: (1140px + $grid-gutter-width);
$grid-float-breakpoint: 992px;

// Fonts
$font-family-sans-serif: "Dosis", "Helvetica Neue", Arial, sans-serif;
$font-size-base: 15px;
$font-size-small: 13px;
$font-size-h1: 28px;
$font-size-h2: 20px;
$font-size-h3: $font-size-base;
$font-size-h4: $font-size-base;
$font-size-h5: $font-size-base;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 500;
$line-height-base: 1.5625;
$headings-line-height: 1.1;
$headings-font-weight: $font-weight-bold;

// Colors
$color-white: #ffffff;
$color-black: #000000;
$color-light-black: #323232;
$color-blue: #325086;
$color-gray: #f5f5f6;
$color-dark-gray: #555a5e;
$color-red: #d03238;
$brand-primary: $color-blue;
$brand-secondary: rgba(0,0,0,0.6);
$text-color: $color-black;
$link-color: $brand-primary;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;
$callout-caption-bgcolor: $color-dark-gray;

// Components
$border-radius-base: 0;
$well-border: transparent;
$well-bg: $brand-secondary;
$well-color: $color-white;
$input-border-focus: $brand-primary;

@mixin nav-link-font {
  font-family: 'Dosis';
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: $color-light-black;
}

@mixin nav-link-current-font {
  @include nav-link-font;
  color: $color-red;
}

@mixin callout-font {
  font-family: 'Dosis';
  font-size: 50px;
  font-weight: 600;
  line-height: 65px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: $color-white;

  @media (max-width: $screen-md-max) {
    font-size: 35px;
    line-height: 45px;
  }
}

@mixin headline1-font {
  font-family: 'Dosis';
  font-size: 20px;
  font-weight: 700;
  line-height: 35px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: $color-black;
}

@mixin body-font {
  font-family: 'Dosis';
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: $color-black;
}
