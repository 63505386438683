.contact-column {
  @include make-sm-column(12);
}

.contact-submit {
  @include make-md-column(12);
}

 .btn-primary, .btn-primary:hover {
    background-color: $color-red;
    border-color: $color-red;
 }

.contact-confirmation,
.contact-error {
  display: none;
  border: 0;
  border-radius: 0;

  &.alert-success {
    background-color: $brand-primary;
    color: $color-white;
  }

  b {
    font-weight: bold;
  }

  .debug {
    font-size: $font-size-small;
  }
}

.contact-map #map{
  height: 548px;
}
