.grid-content {
  padding: 35px 0;

  &:first-child {
    padding-top: 135px;
  }

  @include body-font;

  .col-2 {
    @include make-sm-column(4);
    @include make-md-column(2);
  }

  .col-6 {
    @include make-sm-column(7);
    @include make-md-column(6);
  }

  .col-offset-4 {
    @include make-sm-column-offset(0);
    @include make-md-column-offset(4);
  }

  h1 {
    @include headline1-font;

    margin-top: 0;
  }

  .neos-nodetypes-image {
    img {
      width: 100%;
      height: auto;
    }
  }

  .back a {

    &:before {
      content: "<";
      font-family: "Dosis", sans-serif;
      color: $color-red;
      font-size: 22px;
      margin-right: 10px;
    }

    &, &:hover {
      color: $color-dark-gray;
    }
  }

  .empty {
    height: 0;
  }
}

.unternehmenImgText {
  padding: 35px 0 8px;

  & + .unternehmenImgText {
    padding: 8px 0;
  }

  &:last-child {
    padding-bottom: 35px;
  }

  .neos-nodetypes-image {
    figure img {
      display: block;
      width: 100px;
      margin: 0 auto;
      text-align: center;
    }
  }

  .neos-nodetypes-image + .neos-nodetypes-text {
    margin-top: 15px;

    p {
      font-size: 14px;
      line-height: 22px;

      strong {
        font-weight: 600;
      }
    }

    a {
      color: $color-dark-gray;

      &:hover {
        color: $color-red;
      }
    }
  }
}

.grid-content.content-bg {
  background: $color-gray;
}

.grid-content.details {
  .col-6.col-offset-4 {
    @include make-sm-column(12);
    @include make-md-column(6);
  }
}

@media (max-width: $screen-xs-max) {
  .grid-content .more {
    width: 100%;
    text-align: center;
    margin-right: 0;

    & + .neos-nodetypes-image {

      figure img {
        display: block;
        width: 40px;
        margin: 0 auto;
        text-align: center;
      }
    }
  }
}

.bg-icon {
  background:transparent url('../Images/layout/logo-icon-bg.svg') left 5px no-repeat;
  background-size: 30px 50px;

  p {
    padding-left: 40px;
  }
}

.col-1.col-offset-4 {
  @include make-xs-column(2);
  @include make-md-column(1);
  padding: 0;

  .neos-nodetypes-image{
    margin: 0;

    figure img {
      display: block;
      float: left;
      width: 100%;
      max-width: 80px;
    }



    @media (min-width: $screen-sm-min) {
      margin-top: -11px;

      &.download-icon {
        margin-top: -28px;
      }

    }
  }

  +.col-5 {
    @include make-xs-column(10);
    @include make-md-column(5);

    .neos-nodetypes-text{
      strong {
        font-weight: 500;
      }

      ul {
        list-style-type: none;
        margin-top: 30px;
        padding-left: 0;

        li {
          line-height: 35px;
        }

        a {
          color: $color-black;
          text-decoration: underline;

          &:hover {
            color: $color-red;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-sm-min) {
  .grid-content {
    &:first-child {
      padding-top: 35px;
    }

    .empty {
      height: 60px;
    }
  }

  .grid-content .more {
    display: block;
    float: left;
    margin-bottom: 0;
    margin-right: 25px;
    line-height: 45px;

    &+ .neos-nodetypes-image {
      figure img {
        display: block;
        float: left;
        width: 40px;
        margin: 0;
      }
    }
  }
}

@media (min-width: $screen-sm-min) and  (max-width: $screen-sm-max) {
  .col-1 + .col-4 {
    @include make-sm-column(12);

    & + .col-3 {
      @include make-sm-column(12);
    }
  }
}

@media (min-width: $screen-md-min) {
  .grid-content {

    h1 {
      padding-right: 30%;
    }

    .empty {
      height: 120px;
    }
  }

  .bg-icon {
    background:transparent url('../Images/layout/logo-icon-bg.svg') 55px 5px no-repeat;
    background-size: 30px 50px;

    p {
      padding-left: 95px;
    }
  }
}
