@import "_footer_base";

@mixin footer-compact() {
  @include footer-base;

  .footer {
    @include clearfix;
  }

  .footer-about {
    @include make-sm-column(4);
  }

  .footer-nav {
    @include make-sm-column(4);
    @include make-sm-column-offset(4);
  }

  .footer-nav-list {
    padding: 0;

    @media (max-width: $screen-sm-max) {
      margin-top: 10px;

      li {
        padding: 0;
        display: block;
      }
    }
  }
}
