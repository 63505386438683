$header-logo-height-md: 100px;
$header-logo-height-xs: $header-logo-height-md/2;

@include header-compact;

.header {
  position: fixed;
  top: 0;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 35px;
  z-index: 1000;
  background-color: rgba(255,255,255,0.8);
}

.scroll .header {
  padding: 10px 0;
  background-color: rgba(255,255,255,0.95);
}

.header-content {
  @extend .container;
}

.header-logo {
  @include make-md-column(4);

  a {
    display: block;
    width: 100%;
  }
}

.logo {
  display: block;
  width: 200px;
  height: 48px;
  background: url("../Images/layout/Hopp_Logo.svg") no-repeat;
  background-size: 200px 48px;
}

.scroll .logo {
  display: block;
  width: 100px;
  height: 39px;
  background: url("../Images/layout/Hopp_Logo_Icon.svg") no-repeat left 11px;
  background-size: 100px 28px;
}

.header-nav {
  @media (min-width: $screen-md) {
    margin-top: 52px;
  }

  @include make-md-column(7);
  @include nav-link-font;
}

.header-nav-list {
  float: left;
  text-align: center;

  @media (max-width: $screen-sm-max) {
    width: 100%;
    padding-top: 80px;
    margin-bottom: 0;
  }

  li {
    @media (min-width: $screen-md) {
      padding-left: 20px;
    }

    &.current a {
      @include nav-link-current-font;
    }
  }
}

.scroll .header-nav {
  height: 50px;
}

@media (min-width: $screen-md) {
  .scroll .header {
    padding: 15px 0;
  }

  .logo {
    display: block;
    width: 300px;
    height: 73px;
    background: url("../Images/layout/Hopp_Logo.svg") no-repeat left top;
    background-size: 300px 73px;
  }

  .scroll .logo {
    display: block;
    width: 174px;
    height: 50px;
    background: url("../Images/layout/Hopp_Logo_Icon.svg") no-repeat left top;
    background-size: 174px 50px;
  }

  .header-nav-list {
    float: right;
  }

  .header-nav-sub {
    margin: 10px 0;
    font-size: $font-size-small;

    li {
      padding-right: 5px;
    }
  }

  .scroll .header-nav {
    margin-top: 0px;
    line-height: 50px;
    height: 50px;
  }
}
.header-nav-content.collapse.show {
  display: block;
  text-align: right;
}

.header-nav-toggle {
  position: absolute;
  right: 0;
}